import styled, { css } from 'styled-components';
import { size } from 'polished';

export const NotificationWrapper = styled.div`
  ${({ theme, count }) => css`
    position: relative;

    &:after {
      position: absolute;
      top: -2px;
      right: -2px;

      ${size('12px')}
      display: ${count > 0 ? 'block' : 'none'};

      content: '${count}';
      font-size: 8px;
      text-align: center;
      line-height: 120%;
      border-radius: 100%;
      font-weight: bolder;
      color: ${theme.colors.secondary.main};
      background-color: ${theme.colors.primary.main};
    }
  `}
`;
