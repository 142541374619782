import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { PATH_PAGE } from 'src/routes/paths';

import { httpClient } from '@verde/utils';
import { Notification, useAuthContext } from '@verde/modules';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

function AuthProtect({ children }) {
  const location = useLocation();
  const { isLoading, isAuthenticated } = useAuthContext();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: PATH_PAGE.auth.login,
          ...(location.pathname &&
            location.pathname !== '/' && {
              search: `?continue=${location.pathname}`
            })
        }}
      />
    );
  }

  return (
    <Notification.Provider enabled={!!httpClient.getBearerToken()}>
      {children}
    </Notification.Provider>
  );
}

export default AuthProtect;
