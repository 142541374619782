import { ProposalActiveState } from '@verde/entities';
import {
  ArchiveXIcon,
  CheckIcon,
  EllipsisHorizontalIcon,
  ReturnIcon,
  XIcon
} from '@verde/icons';
import { tv } from 'tailwind-variants';

const timeTrackingIconStyles = tv({
  slots: {
    base: 'h-8 w-8 rounded-full p-1',
    icon: 'text-neutral-dark-1'
  },
  variants: {
    kind: {
      DOING: { base: 'bg-neutral-light-1', icon: 'text-neutral-light-3' },
      DONE: { base: 'bg-neutral-light-1', icon: 'text-neutral-light-3' },
      NOT_STARTED: { base: 'bg-neutral-light-1', icon: 'text-neutral-light-3' },
      RETURNED: {
        base: 'bg-neutral-light-1',
        icon: 'text-neutral-light-3'
      },
      DECLINED: { base: 'bg-neutral-dark-1', icon: 'text-neutral-light-pure' },
      ARCHIVED: { base: 'bg-neutral-dark-1', icon: 'text-neutral-light-pure' },
      REFUSED: {
        base: 'bg-off-red border border-feedback-error',
        icon: 'text-feedback-error'
      },
      REJECTED: {
        base: 'bg-off-red border border-feedback-error',
        icon: 'text-feedback-error'
      }
    },
    isLastOne: {
      true: { base: 'bg-neutral-dark-3', icon: 'text-primary-main' }
    }
  },
  compoundVariants: [
    {
      kind: ['DOING'],
      isLastOne: true,
      className: {
        base: 'bg-neutral-dark-3',
        icon: 'text-primary-main'
      }
    },
    {
      kind: ['REFUSED', 'REJECTED'],
      isLastOne: true,
      className: {
        base: 'bg-off-red border border-feedback-error',
        icon: 'text-feedback-error'
      }
    },
    {
      kind: ['ARCHIVED', 'DECLINED'],
      isLastOne: true,
      className: { base: 'bg-neutral-dark-1', icon: 'text-neutral-light-pure' }
    }
  ]
});

const iconsByKind = (
  kind: Props['kind'],
  isLastOne: boolean,
  className: string
) => {
  const customIcon = [
    'RETURNED',
    'ARCHIVED',
    'DECLINED',
    'REJECTED',
    'REFUSED',
    'DONE'
  ];

  if (!customIcon.includes(kind) && isLastOne)
    return <EllipsisHorizontalIcon className={className} />;

  return {
    NOT_STARTED: <CheckIcon className={className} />,
    DOING: <CheckIcon className={className} />,
    DONE: <CheckIcon className={className} />,
    RETURNED: <ReturnIcon className={className} />,
    ARCHIVED: <ArchiveXIcon className={className} />,
    DECLINED: <ArchiveXIcon className={className} />,
    REJECTED: <XIcon className={className} />,
    REFUSED: <XIcon className={className} />
  }[kind];
};
export function TimeTrackingIcon({ kind, isLastOne }: Props) {
  return (
    <div className={timeTrackingIconStyles().base({ kind, isLastOne })}>
      {iconsByKind(
        kind,
        isLastOne,
        timeTrackingIconStyles().icon({ kind, isLastOne })
      )}
    </div>
  );
}

export interface Props {
  kind: ProposalActiveState['step_action'];
  isLastOne: boolean;
}
