import { useMemo } from 'react';
import { TimeTrackingIcon, Props as IconProps } from './icon';
import { dayjs } from '@verde/utils';

export function TimeTrackingStep({
  kind,
  text,
  date,
  business_days,
  isLastOne
}: Props) {
  const shouldShowFlowLine = useMemo(
    () => ['NOT_STARTED'].includes(kind),
    [kind]
  );

  return (
    <div className="grid grid-cols-[32px_1fr] items-center gap-2">
      <TimeTrackingIcon kind={kind} isLastOne={isLastOne} />

      <div className="flex w-full flex-col">
        <div className="flex items-center gap-1">
          <p className="font-semibold">{text}</p>
          {(!isLastOne || shouldShowFlowLine) && (
            <div className="h-1 w-full min-w-[56px] rounded bg-neutral-light-3" />
          )}
        </div>
        <p className="whitespace-nowrap text-base text-neutral-dark-1">
          {dayjs(date).format('DD/MM/YY')} {business_days}
        </p>
      </div>
    </div>
  );
}

export interface Props extends Pick<IconProps, 'kind'> {
  text: string;
  date: dayjs;
  business_days: string;
  isLastOne: boolean;
}
