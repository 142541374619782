import { useAuthContext } from '@verde/modules';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { PATH_APP } from 'src/routes/paths';

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

function GuestProtect({ children }) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { isLoading, isAuthenticated } = useAuthContext();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    const continueAt = query.get('continue');
    return <Navigate to={continueAt || PATH_APP.root} />;
  }

  return <>{children}</>;
}

export default GuestProtect;
