import { lazy } from "@verde/utils";
import AuthProtect from "src/components/Auth/AuthProtect";
import { Loadable } from "src/components/loadable";
import { LayoutDefault } from "src/layouts/Default";
import { PATH_APP } from "src/routes/paths";

const ProducersList = Loadable(lazy(() => import("./list")));
const ProducersDetails = Loadable(lazy(() => import("./detail")));
const Rating = Loadable(lazy(() => import("./detail/tabs/rating")));
const Debts = Loadable(lazy(() => import("./detail/tabs/debts")));
const BasicData = Loadable(lazy(() => import("./detail/tabs/basic-data")));
const Productions = Loadable(lazy(() => import("./detail/tabs/productions")));
const Properties = Loadable(lazy(() => import("./detail/tabs/properties")));
const ProductionPartners = Loadable(
  lazy(() => import("./detail/tabs/production-partners")),
);
const Documents = Loadable(lazy(() => import("./detail/tabs/documents")));

const producersRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.clients.root,
      element: <ProducersList />,
    },
    {
      path: PATH_APP.clients.details,
      element: <ProducersDetails />,
      children: [
        {
          path: PATH_APP.clients.details,
          element: <Rating />,
        },
        {
          path: PATH_APP.clients.debts,
          element: <Debts />,
        },
        {
          path: PATH_APP.clients.basicData,
          element: <BasicData />,
        },
        {
          path: PATH_APP.clients.productions,
          element: <Productions />,
        },
        {
          path: PATH_APP.clients.properties,
          element: <Properties />,
        },
        {
          path: PATH_APP.clients.productionPartners,
          element: <ProductionPartners />,
        },
        {
          path: PATH_APP.clients.documents,
          element: <Documents />,
        },
      ],
    },
  ],
};

export default producersRoutes;
