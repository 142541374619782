import { httpClient, translation } from "@verde/utils";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { PATH_PAGE } from "src/routes/paths";
import App from "./App";
import "./styles.css";
import TagManager from "react-gtm-module";

httpClient.init({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/admin`,
  onExpiredToken: () => {
    window.location.assign(PATH_PAGE.auth.login);
  },
});

translation.init({
  lng: "pt",
});

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID || "",
  });
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root"),
);
