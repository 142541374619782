import { ReactNode, useEffect } from 'react';
import { create } from 'zustand';

import { Proposal } from '@verde/modules';
import { useDuplicateProposal, useRenewProposal } from '@verde/entities';

type Kind = 'renegotiation' | 'renew' | 'duplicate';

export const useProposalActions = create<{
  isOpen: boolean;
  proposalId: number | undefined;
  action: (proposalId: number, kind: Kind) => void;
  close: () => void;
  kind: Kind | undefined;
}>((set) => ({
  isOpen: false,
  proposalId: undefined,
  kind: undefined,
  action: (proposalId: number, kind: Kind) => {
    set(() => ({ isOpen: true, proposalId, kind }));
  },
  close: () =>
    set(() => ({ isOpen: false, proposalId: undefined, kind: undefined }))
}));

export function WithProposalActionsRender({
  children
}: {
  children: ReactNode;
}) {
  return (
    <>
      {children}

      <ProposalActionsRender />
    </>
  );
}

function ProposalActionsRender() {
  const { isOpen, proposalId, close, kind } = useProposalActions();
  const duplicate = useDuplicateProposal();
  const renew = useRenewProposal();

  useEffect(() => {
    if (kind && proposalId && kind !== 'renegotiation') {
      const action = {
        duplicate: async () => {
          const duplicatesProposal = await duplicate.mutateAsync(proposalId);
          window.open(`/propostas/${duplicatesProposal.id}`);
        },
        renew: async () => {
          const RenewedProposal = await renew.mutateAsync(proposalId);
          window.open(`/propostas/${RenewedProposal.id}`);
        }
      }[kind];

      if (action) {
        action();
      }
    }
  }, [kind, proposalId]);

  if (kind === 'renegotiation') {
    return (
      <Proposal.RenegotiationWidgetForm
        onClose={close}
        open={isOpen}
        proposalId={proposalId}
      />
    );
  }

  return null;
}
