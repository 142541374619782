import { lazy } from "@verde/utils";
import { Navigate } from "react-router-dom";
import AuthProtect from "src/components/Auth/AuthProtect";
import { Loadable } from "src/components/loadable";
import { LayoutDefault } from "src/layouts/Default";
import { PATH_APP } from "src/routes/paths";

const Dashboard = Loadable(lazy(() => import("./dashboard")));
const Account = Loadable(lazy(() => import("./account")));

const generalRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.root,
      element: <Navigate to={PATH_APP.general.dashboard} replace />,
    },
    {
      path: PATH_APP.general.dashboard,
      element: <Dashboard />,
    },
    {
      path: PATH_APP.general.account,
      element: <Account />,
    },
  ],
};

export default generalRoutes;
