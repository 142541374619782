import { lazy } from "@verde/utils";
import AuthProtect from "src/components/Auth/AuthProtect";
import { Loadable } from "src/components/loadable";
import { WithProposalActionsRender } from "src/hooks/proposalActions";
import { WithSLARender } from "src/hooks/sla";
import { LayoutDefault } from "src/layouts/Default";
import { PATH_APP } from "src/routes/paths";

const ProposalsList = Loadable(lazy(() => import("./list")));
const ProposalsDetails = Loadable(lazy(() => import("./detail")));
const Resume = Loadable(lazy(() => import("./detail/tabs/resume")));
const Productions = Loadable(lazy(() => import("./detail/tabs/productions")));
const Properties = Loadable(lazy(() => import("./detail/tabs/properties")));
const ProductionPartners = Loadable(
  lazy(() => import("./detail/tabs/production-partners")),
);
const Warranties = Loadable(lazy(() => import("./detail/tabs/warranties")));
const Contract = Loadable(lazy(() => import("./detail/tabs/contract")));
const Payments = Loadable(lazy(() => import("./detail/tabs/payments")));

const proposalsRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <WithProposalActionsRender>
        <WithSLARender>
          <LayoutDefault />
        </WithSLARender>
      </WithProposalActionsRender>
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.proposals.root,
      element: <ProposalsList />,
    },
    {
      path: PATH_APP.proposals.details,
      element: <ProposalsDetails />,
      children: [
        {
          path: PATH_APP.proposals.details,
          element: <Resume />,
        },
        {
          path: PATH_APP.proposals.productions,
          element: <Productions />,
        },
        {
          path: PATH_APP.proposals.properties,
          element: <Properties />,
        },
        {
          path: PATH_APP.proposals.productionPartners,
          element: <ProductionPartners />,
        },
        {
          path: PATH_APP.proposals.warranties,
          element: <Warranties />,
        },
        {
          path: PATH_APP.proposals.contract,
          element: <Contract />,
        },
        {
          path: PATH_APP.proposals.payments,
          element: <Payments />,
        },
      ],
    },
  ],
};

export default proposalsRoutes;
