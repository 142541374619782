import { Navigate, useRoutes } from "react-router-dom";

import advisorsRoutes from "src/modules/advisors/advisors.routes";
import authRoutes from "src/modules/auth/auth.routes";
import configsRoutes from "src/modules/configs/configs.routes";
import generalRoutes from "src/modules/general/general.routes";
import juridicalPersonRoutes from "src/modules/juridicalPerson/juridicalPerson.routes";
import peopleRoutes from "src/modules/people/people.routes";
import producersRoutes from "src/modules/producers/producers.routes";
import proposalsRoutes from "src/modules/proposals/proposals.routes";
import NotFound from "src/pages/NotFound";

export default function Router() {
  return useRoutes([
    authRoutes,
    generalRoutes,
    proposalsRoutes,
    producersRoutes,
    advisorsRoutes,
    juridicalPersonRoutes,
    peopleRoutes,
    configsRoutes,
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
