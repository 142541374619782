import { lazy } from "@verde/utils";
import AuthProtect from "src/components/Auth/AuthProtect";
import { Loadable } from "src/components/loadable";
import { LayoutDefault } from "src/layouts/Default";
import { PATH_APP } from "src/routes/paths";

const VerdeConsultList = Loadable(lazy(() => import("./verde-consult/list")));
const configRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.verdeConsult.root,
      element: <VerdeConsultList />,
    },
  ],
};

export default configRoutes;
