import { Box, Spinner } from '@verde/ui';

function LoadingScreen() {
  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Box>
  );
}

export default LoadingScreen;
