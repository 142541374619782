import { lazy } from "@verde/utils";
import AuthProtect from "src/components/Auth/AuthProtect";
import { Loadable } from "src/components/loadable";
import { LayoutDefault } from "src/layouts/Default";
import { PATH_APP } from "src/routes/paths";

const Consult = Loadable(lazy(() => import("./consult")));

const juridicalPersonRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.juridicalPerson.consult,
      element: <Consult />,
    },
  ],
};

export default juridicalPersonRoutes;
