import { lazy } from "@verde/utils";
import GuestProtect from "src/components/Auth/GuestProtect";
import { Loadable } from "src/components/loadable";
import { PATH_PAGE } from "src/routes/paths";

const Login = Loadable(lazy(() => import("./login")));

const authRoutes = {
  path: PATH_PAGE.auth.root,
  children: [
    {
      path: PATH_PAGE.auth.login,
      element: (
        <GuestProtect>
          <Login />
        </GuestProtect>
      ),
    },
  ],
};

export default authRoutes;
