import { lazy } from "@verde/utils";
import AuthProtect from "src/components/Auth/AuthProtect";
import { Loadable } from "src/components/loadable";
import { LayoutDefault } from "src/layouts/Default";
import { PATH_APP } from "src/routes/paths";

const List = Loadable(lazy(() => import("./list")));
const Details = Loadable(lazy(() => import("./detail")));
const Rating = Loadable(lazy(() => import("./detail/tabs/rating")));
const Debts = Loadable(lazy(() => import("./detail/tabs/debts")));
const BasicData = Loadable(lazy(() => import("./detail/tabs/basic-data")));

const peoplePhysicalRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.people.root,
      element: <List />,
    },
    {
      path: PATH_APP.people.details,
      element: <Details />,
      children: [
        {
          path: PATH_APP.people.details,
          element: <Rating />,
        },
        {
          path: PATH_APP.people.debts,
          element: <Debts />,
        },
        {
          path: PATH_APP.people.basicData,
          element: <BasicData />,
        },
      ],
    },
  ],
};

export default peoplePhysicalRoutes;
