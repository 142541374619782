function path<Root extends string, Sublink extends string>(
  root: Root,
  sublink: Sublink,
): `${Root}${Sublink}` {
  return `${root}${sublink}`;
}

const ROOTS = {
  app: "/",
  auth: "/auth",
} as const;

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, "/login"),
  },
} as const;

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, "dashboard"),
    dashboard: path(ROOTS.app, "dashboard"),
    account: path(ROOTS.app, "minha-conta"),
  },
  proposals: {
    root: path(ROOTS.app, "propostas"),
    details: path(ROOTS.app, "propostas/:id"),
    productions: path(ROOTS.app, "propostas/:id/producoes"),
    properties: path(ROOTS.app, "propostas/:id/propriedades"),
    productionPartners: path(ROOTS.app, "propostas/:id/participantes"),
    warranties: path(ROOTS.app, "propostas/:id/garantias"),
    contract: path(ROOTS.app, "propostas/:id/contrato"),
    payments: path(ROOTS.app, "propostas/:id/pagamentos"),
  },
  clients: {
    root: path(ROOTS.app, "produtores"),
    details: path(ROOTS.app, "produtores/clientes/:id"),
    debts: path(ROOTS.app, "produtores/clientes/:id/endividamento"),
    basicData: path(ROOTS.app, "produtores/clientes/:id/dados-basicos"),
    productions: path(ROOTS.app, "produtores/clientes/:id/producoes"),
    properties: path(ROOTS.app, "produtores/clientes/:id/propriedades"),
    productionPartners: path(
      ROOTS.app,
      "produtores/clientes/:id/participantes",
    ),
    documents: path(ROOTS.app, "produtores/clientes/:id/documentos"),
  },
  advisors: {
    root: path(ROOTS.app, "assessores"),
    details: path(ROOTS.app, "assessores/:id"),
    edit: path(ROOTS.app, "assessores/:id/editar"),
    missions: path(ROOTS.app, "assessores/:id/missoes"),
  },
  people: {
    root: path(ROOTS.app, "pessoas_fisicas"),
    details: path(ROOTS.app, "pessoas_fisicas/:id"),
    debts: path(ROOTS.app, "pessoas_fisicas/:id/endividamento"),
    basicData: path(ROOTS.app, "pessoas_fisicas/:id/dados-basicos"),
  },
  juridicalPerson: {
    consult: path(ROOTS.app, "pessoas-juridicas/consultar"),
  },
  verdeConsult: {
    root: path(ROOTS.app, "configuracoes/verde-consult"),
  },
} as const;
