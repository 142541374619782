import { AlertDialog } from "@verde/compose-ui";
import { AuthProvider } from "@verde/modules";
import { ThemeProvider } from "@verde/ui";
import {
  AlertProvider,
  FloatingProvider,
  QueryProvider,
  ToastProvider,
} from "@verde/utils";

import Router from "src/routes";

function App() {
  return (
    <QueryProvider>
      <ThemeProvider>
        <AlertProvider dialog={(alert) => <AlertDialog alert={alert} />}>
          <FloatingProvider>
            <>
              <ToastProvider />
              <AuthProvider
                context="admin"
                baseUrl={process.env.REACT_APP_API_URL || ""}
              >
                <div className="prose">
                  <Router />
                </div>
              </AuthProvider>
            </>
          </FloatingProvider>
        </AlertProvider>
      </ThemeProvider>
    </QueryProvider>
  );
}

export default App;
