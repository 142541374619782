import { Card, CellList, Dialog, LabeledDivider } from '@verde/compose-ui';
import { formatDate, useTranslation } from '@verde/utils';
import { Alert, Skeleton, Tag, Text, Tooltip } from '@verde/ui-core';
import { ClockIcon } from '@verde/icons';
import { create } from 'zustand';
import { motion } from 'framer-motion';

import {
  ProposalStatus,
  ProposalStepAction,
  useFetchSLAInfo
} from '@verde/entities';
import { TimeTracking } from 'src/modules/proposals/components';
import { ReactNode, useEffect, useMemo, useRef } from 'react';

export const useSLA = create<{
  isOpen: boolean;
  proposalId: number | undefined;
  open: (proposalId: number) => void;
  close: () => void;
}>((set) => ({
  isOpen: false,
  proposalId: undefined,
  open: (proposalId: number) => {
    set((state) => ({ ...state, isOpen: true, proposalId }));
  },
  close: () => set(() => ({ isOpen: false, proposalId: undefined }))
}));

const colorByStatus = (status: ProposalStatus): string =>
  ({
    PROPOSAL: '#FFFDF1',
    CONTRACT: '#FFF4DB',
    PAYMENT: '#F3E8D5',
    MONITORING: '#C6B3A2'
  }[status || 'PROPOSAL']);

const animate = {
  container: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.015
      }
    }
  },
  item: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1
    }
  }
};

export function WithSLARender({ children }: { children: ReactNode }) {
  return (
    <>
      {children}

      <SLARender />
    </>
  );
}

function SLARender() {
  const { t } = useTranslation();
  const { isOpen, proposalId, close } = useSLA();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { data: sla, isLoading } = useFetchSLAInfo(proposalId);

  const flowStatuses = useMemo(
    () =>
      sla?.proposal_states?.filter(({ step, action }) => {
        if (step === 'SETTLED') {
          const alreadyDone = sla.proposal_states.find(
            (states) => states.step === 'SETTLED' && states.action === 'DONE'
          );

          if (action !== 'DONE' && alreadyDone) return false;
        }

        return true;
      }),
    [sla?.proposal_states]
  );

  const uniqueStatuses = useMemo(
    () =>
      flowStatuses?.reduce((statuses, current, index) => {
        if (flowStatuses?.[index - 1]?.status === current?.status) {
          return statuses;
        }

        if (statuses?.length === 0) {
          return [{ status: current.status, index: 1 }];
        }

        return [...statuses, { status: current.status, index: index + 1 }];
      }, []),
    [flowStatuses]
  );

  useEffect(() => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef?.current;
      container.scrollLeft = container?.scrollWidth;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowStatuses, scrollContainerRef.current]);

  const handleWheel = (event) => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const scrollAmount = event.deltaY;
      container.scrollLeft += scrollAmount;
    }
  };

  if (!isOpen) return null;

  const isCustomAction = (action: ProposalStepAction) => {
    return ['ARCHIVED', 'REJECTED', 'REFUSED', 'DECLINED'].includes(action);
  };

  return (
    <>
      {isOpen && (
        <Dialog
          open
          size="lg"
          classNames={{
            body: 'flex flex-col gap-4'
          }}
          header={{
            title: (
              <h1 className="text-2xl font-bold">
                Movimentação e SLA - ID #{proposalId}
              </h1>
            ),
            description:
              'Acompanhe as datas e o tempo que a operação se movimentou em cada fase e etapa.',
            kind: 'warning'
          }}
          onClose={() => close()}
        >
          {sla?.proposal_states?.length === 0 ? (
            <Alert kind="warning" bordered>
              Esta proposta não tem informações mínimas para gerar métricas.
            </Alert>
          ) : (
            <>
              <Card
                classNames={{
                  base: 'border p-6',
                  content: 'flex gap-10 flex-col overflow-hidden'
                }}
              >
                {isLoading ? (
                  <>
                    <div className="grid grid-rows-2 gap-2">
                      <Skeleton height={38} />
                      <Skeleton height={41} />
                    </div>
                    <Skeleton height={28} />
                  </>
                ) : (
                  <>
                    <motion.div
                      variants={animate.container}
                      initial="hidden"
                      animate="show"
                    >
                      <div
                        style={{
                          gridTemplateColumns: `repeat(${flowStatuses?.length}, auto)`
                        }}
                        ref={scrollContainerRef}
                        onWheel={handleWheel}
                        className="grid grid-rows-2 gap-x-1 gap-y-3 overflow-x-auto scroll-smooth whitespace-nowrap pb-4 scrollbar-thin"
                      >
                        {uniqueStatuses?.map(({ status, index }, currIndex) => {
                          const colEnd =
                            uniqueStatuses[currIndex + 1]?.index ||
                            flowStatuses.length + 1;

                          return (
                            <motion.div
                              style={{
                                backgroundColor: colorByStatus(status),
                                gridArea: `1/${index}/1/${colEnd}`
                              }}
                              className="flex h-fit w-full justify-between gap-2 rounded bg-off-yellow px-4 py-2"
                              key={`mission-progress-bar-item-${index}`}
                              variants={animate.item}
                            >
                              <Text>{t(status)}</Text>
                              <Text>
                                {sla?.proposal_status_business_days?.[currIndex]
                                  ?.business_days || '-'}
                              </Text>
                            </motion.div>
                          );
                        })}

                        {flowStatuses?.map(
                          (
                            { step, action, business_days, created_at },
                            index
                          ) => {
                            let isLastStatus =
                              index === flowStatuses?.length - 1;

                            const shouldDisplayAction =
                              ['DOING'].includes(action) && index !== 0;

                            if (shouldDisplayAction && step !== 'SETTLED')
                              return (
                                <motion.div
                                  className="row-start-2 grid items-center"
                                  key={`mission-progress-bar-item-${index}`}
                                  variants={animate.item}
                                >
                                  <Tooltip content={t(action)}>
                                    <TimeTracking.Action
                                      key={`time-tracking-action-${index}`}
                                      business_days={business_days}
                                      step={step}
                                      date={created_at}
                                    />
                                  </Tooltip>
                                </motion.div>
                              );

                            const isCustomStatus = isCustomAction(action);

                            const isSameStep = flowStatuses.every(
                              (flow, flowIndex) => {
                                const ignoreSameStep = isCustomAction(
                                  flow.action
                                );

                                if (isCustomStatus || ignoreSameStep)
                                  return false;
                                if (flowIndex < index) return true;
                                return flow.step === step;
                              }
                            );

                            return (
                              <motion.div
                                className="row-start-2 grid"
                                key={`mission-progress-bar-item-${index}`}
                                variants={animate.item}
                              >
                                <TimeTracking.Step
                                  key={`time-tracking-step-${index}`}
                                  isLastOne={isLastStatus || isSameStep}
                                  kind={action}
                                  text={t(isCustomStatus ? action : step)}
                                  date={created_at}
                                  business_days={business_days}
                                />
                              </motion.div>
                            );
                          }
                        )}
                      </div>
                    </motion.div>

                    <LabeledDivider>
                      <Text>
                        Recebida em {formatDate(sla?.start_proposal_date)}
                      </Text>

                      <Tag
                        icon={<ClockIcon />}
                        label={sla?.active_since_days}
                      />
                    </LabeledDivider>
                  </>
                )}
              </Card>

              <div className="flex gap-2">
                {isLoading ? (
                  [0, 1, 2, 3].map(() => <Skeleton height={62} />)
                ) : (
                  <>
                    <Card
                      classNames={{
                        base: 'border w-min',
                        content: 'flex flex-row'
                      }}
                    >
                      <Tag kind="secondary" label={t(sla?.active_status)} />
                      <Tag label={t(sla?.active_step)} />
                    </Card>

                    <CellList
                      size="sm"
                      classNames={{ yHeaders: 'items-center' }}
                      headers={[
                        {
                          key: 'sla',
                          label: (
                            <div className="flex items-center gap-1">
                              <div className="h-3 w-3 rounded bg-neutral-light-3" />
                              <Text>SLA ops Verde:</Text>
                            </div>
                          )
                        }
                      ]}
                      cells={[{ sla: sla.active_since_days }]}
                    />

                    {/* <CellList
                      size="sm"
                      classNames={{ yHeaders: 'items-center' }}
                      headers={[
                        {
                          key: 'sla',
                          label: (
                            <div className="flex items-center gap-1">
                              <div className="h-3 w-3 rounded bg-neutral-light-3" />
                              <Text>SLA AA:</Text>
                            </div>
                          )
                        }
                      ]}
                      cells={[{ sla: '{sla_aa_date}' }]}
                    /> */}

                    <CellList
                      size="sm"
                      classNames={{ yHeaders: 'items-center' }}
                      headers={[
                        {
                          key: 'sla',
                          label: (
                            <div className="flex items-center gap-1">
                              <Text>Arquivado ({sla?.archived_count}x):</Text>
                            </div>
                          )
                        }
                      ]}
                      cells={[{ sla: `${sla?.archived_date || '-'}` }]}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </Dialog>
      )}
    </>
  );
}
