import { useCallback, useReducer, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Box,
  Alert,
  IconButton,
  FormHelperText,
  Select
} from '@verde/ui';
import { formatDate, toast } from '@verde/utils';

import { useAuthContext } from '@verde/modules';

const TEAM_ID = 'T02JAUZF3JP';
const CHANNEL_ID = 'C040NPQKQAK';
const DELAY = 3000;

const PRIORITIES = ['Baixa', 'Média', 'Alta'];

function makeBugReportTemplate({ name, priority, description }) {
  return `
:warning: BUG REPORT :warning:




:vertical_traffic_light: Prioridade:
${priority}


:speaking_head_in_silhouette: Autor:
${name}


:date: Data:
${formatDate(new Date().toISOString(), { date: 'full', display: 'both' })}


:link: URL:
${window.location.href}


:mega: Descrição:
${description}
`.trim();
}

export function BugReportDialog({
  screenshot,
  onClose
}: BugReportDialog.Props) {
  const { user } = useAuthContext();

  const [isLoading, toggleIsLoading] = useReducer(
    (isLoading) => !isLoading,
    false
  );

  const [priority, setPriority] = useState(null);
  const [description, setDescription] = useState('');

  const isDisabled = !priority || !description;

  const handleSubmit = useCallback(async () => {
    try {
      toggleIsLoading();
      await navigator.clipboard.write([
        new ClipboardItem({
          [screenshot.type]: screenshot,
          'text/plain': new Blob(
            [
              makeBugReportTemplate({
                name: user?.name,
                description,
                priority
              })
            ],
            {
              type: 'text/plain'
            }
          )
        })
      ]);

      toast.loading(
        <p>
          Aguarde, estamos abrindo o canal de <b>#tech-bug-report</b> do Slack.
        </p>,
        {
          duration: 2500
        }
      );

      toast(
        <p>
          Lembre-se de utilizar o <code>CTRL + V</code> para colar o template no
          Slack.
        </p>
      );

      await new Promise(function (resolve) {
        setTimeout(resolve, DELAY);
      });

      onClose?.();

      window.open(`slack://channel?team=${TEAM_ID}&id=${CHANNEL_ID}`, '_blank');
    } catch (error) {
      toast.error('Ocorreu um erro por favor tente novamente.');
    } finally {
      toggleIsLoading();
    }
  }, [priority, description, screenshot, user, onClose]);

  return (
    <Modal size="lg" hasBackdrop>
      <ModalContent>
        <ModalHeader>
          Reportar Bug{' '}
          <IconButton onClick={onClose}>
            <XMarkIcon />
          </IconButton>
        </ModalHeader>
        <ModalBody>
          <Box display="grid" spacing="lg">
            <Box display="grid" spacing="sm">
              <Alert severity="danger">
                Antes mesmo de utilizar essa ferramenta, verifique se este bug
                que você encontrou já foi reportado.
              </Alert>
              <Alert severity="info" hasIcon={false}>
                Esta ferramenta possibilita criar um template de Bug Report.
                <br />
                <br />
                Após descrever e clicar em "<b>Enviar report</b>", você será
                automaticamente redirecionado para o canal de{' '}
                <b>#tech-bug-report</b> do Slack.
                <br />
                <br />
                Então, basta apertar <code>CTRL + V</code> para colar o template
                e enviar a mensagem.
              </Alert>
            </Box>
            <FormControl>
              <FormLabel>Prioridade</FormLabel>
              <Select
                isClearable={false}
                value={
                  priority
                    ? {
                        label: PRIORITIES.find((value) => value === priority),
                        value: PRIORITIES.find((value) => value === priority)
                      }
                    : undefined
                }
                onChange={({ value }) => {
                  setPriority(value);
                }}
                options={PRIORITIES.map((priority) => ({
                  label: priority,
                  value: priority
                }))}
              />
              <FormHelperText>
                Classifique qual o grau de risco deste bug.
              </FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Descrição</FormLabel>
              <Textarea
                placeholder="Descreva livremente..."
                rows={8}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
              <FormHelperText>
                Utilize este campo para descrever com o máximo de detalhes o
                problema.
              </FormHelperText>
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} color="white">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            isLoading={isLoading}
            isDisabled={isDisabled}
          >
            Enviar report
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export namespace BugReportDialog {
  export interface Props {
    screenshot: Blob;
    onClose?: () => void;
  }
}
