import { useMemo } from 'react';

import { dayjs } from '@verde/utils';
import { ProposalStep } from '@verde/entities';
import { Text, Tooltip } from '@verde/ui-core';

export function TimeTrackingAction({ date, step, business_days }: Props) {
  const isValidation = useMemo(() => step === 'VALIDATION', [step]);

  return (
    <div className="flex flex-col">
      <Tooltip
        content={
          isValidation && (
            <div>
              <Text className="text-xl font-bold">Início da Análise</Text>
              <Text>Data que a análise da operação foi iniciada</Text>
            </div>
          )
        }
      >
        <div
          data-validation={isValidation}
          className="group grid grid-cols-[12px_1fr] items-center gap-1"
        >
          <div className="min-h-3 min-w-3 max-w-3 h-3 max-h-3 w-3 rounded bg-neutral-light-3 group-data-[validation=true]:bg-brand-seeds-2" />
          <div
            className={`min-w-20 h-1 w-full rounded bg-neutral-light-3 transition-colors group-data-[validation=true]:group-hover:bg-brand-seeds-2`}
          />
        </div>
      </Tooltip>

      <p className="whitespace-nowrap text-base text-neutral-dark-1">
        {dayjs(date).format('DD/MM/YY')} {business_days}
      </p>
    </div>
  );
}

export interface Props {
  business_days: string;
  step: ProposalStep;
  date: dayjs;
}
