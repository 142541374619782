import { lazy } from "@verde/utils";
import AuthProtect from "src/components/Auth/AuthProtect";
import { Loadable } from "src/components/loadable";
import { LayoutDefault } from "src/layouts/Default";
import { PATH_APP } from "src/routes/paths";

const AdvisorsList = Loadable(lazy(() => import("./list")));
const AdvisorsDetails = Loadable(lazy(() => import("./detail")));
const AdvisorsPerformance = Loadable(
  lazy(() => import("./detail/tabs/performance")),
);
const AdvisorsEdit = Loadable(lazy(() => import("./detail/tabs/edit")));
const MissionsListEdit = Loadable(
  lazy(() => import("./detail/tabs/missions-list")),
);

const advisorsRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.advisors.root,
      element: <AdvisorsList />,
    },
    {
      path: PATH_APP.advisors.details,
      element: <AdvisorsDetails />,
      children: [
        {
          path: PATH_APP.advisors.details,
          element: <AdvisorsPerformance />,
        },
        {
          path: PATH_APP.advisors.edit,
          element: <AdvisorsEdit />,
        },
        {
          path: PATH_APP.advisors.missions,
          element: <MissionsListEdit />,
        },
      ],
    },
  ],
};

export default advisorsRoutes;
