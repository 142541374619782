import { useCallback, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid";
import html2canvas from "html2canvas";

import { HeaderNavigation } from "@verde/compose-ui";
import {
  AdjustmentsHorizontalIcon,
  AdvisorIcon,
  BellIcon,
  BuildingIcon,
  CodeIcon,
  DashboardIcon,
  DocumentClipboardIcon,
  FarmProducerIcon,
  LogoutIcon,
  UserIcon,
} from "@verde/icons";
import { Notification, useAuthContext } from "@verde/modules";
import { Button, Tooltip } from "@verde/ui-core";

import { BugReportDialog } from "src/components/BugReportDialog";
import * as S from "./styles";

export function LayoutDefault() {
  const push = useNavigate();
  const { logout, user } = useAuthContext();

  const [state, setState] = useState(undefined);
  const [screenshot, setScreeshot] = useState(undefined);
  const { toggleNotificationsDrawer, unreadNotificationsCount } =
    Notification.useNotifications();

  const handleLogout = useCallback(async () => {
    await logout();
    push("/");
  }, [logout, push]);

  const handleBugReport = useCallback(async () => {
    setState("screenshot-loading");
    await html2canvas(document.querySelector("body")).then((canvas) => {
      canvas.toBlob(async (blob) => {
        setScreeshot(blob);
      });
    });
    setState(undefined);
  }, []);

  return (
    <>
      <HeaderNavigation
        breadcrumb={[
          {
            label: "Backoffice Verde",
            action: () => push("/dashboard"),
          },
        ]}
        menu={[
          {
            children: "Dashboard",
            icon: <DashboardIcon />,
            onClick: () => push("/dashboard"),
          },
          {
            children: "Operações",
            icon: <DocumentClipboardIcon />,
            onClick: () => push("/propostas"),
          },
          {
            children: "Produtores",
            icon: <FarmProducerIcon />,
            onClick: () => push("/produtores"),
          },
          {
            children: "Assessores",
            icon: <AdvisorIcon />,
            onClick: () => push("/assessores"),
          },

          {
            children: "Pessoas Físicas",
            icon: <UserIcon />,
            onClick: () => push("/pessoas_fisicas"),
          },
          {
            children: "Pessoas Jurídicas",
            icon: <BuildingIcon />,
            onClick: () => push("/pessoas-juridicas/consultar"),
          },
          {
            children: "Verde consult",
            icon: <AdjustmentsHorizontalIcon />,
            onClick: () => push("/configuracoes/verde-consult"),
          },
        ]}
        actions={[
          {
            onClick: handleBugReport,
            children: (
              <Tooltip content="Reportar Bug">
                <CodeIcon className="h-4 w-4" />
              </Tooltip>
            ),
            loading: state === "screenshot-loading",
          },
          {
            onClick: toggleNotificationsDrawer,
            children: (
              <S.NotificationWrapper count={unreadNotificationsCount}>
                <BellIcon />
              </S.NotificationWrapper>
            ),
          },
        ]}
        account={{
          avatar: user?.image?.file_url,
          name: user?.name,
          menu: [
            {
              children: "Minha conta",
              icon: <UserIcon />,
              onClick: () => push("/minha-conta"),
            },
            {
              children: "Encerrar sessão",
              icon: <ArrowLeftOnRectangleIcon />,
              onClick: handleLogout,
            },
          ],
          footer: (
            <Button
              kind="secondary"
              size="lg"
              icon={<LogoutIcon />}
              onClick={logout}
            >
              Encerrar sessão
            </Button>
          ),
        }}
      />

      <>
        <div className="container py-8">
          <Outlet />
        </div>

        {screenshot && (
          <BugReportDialog
            screenshot={screenshot}
            onClose={setScreeshot.bind(null, undefined)}
          />
        )}
      </>
    </>
  );
}
